import React from "react";
import "./Checkbox.scss";

const Checkbox = React.memo(
    ({ id, name, checked, onChange, htmlFor, text, withLabel, disabled }) => {
        return (
            <div className="checkbox">
                <div className="checkbox__box">
                    <input
                        type="checkbox"
                        id={id}
                        name={name}
                        checked={checked}
                        onChange={onChange}
                        disabled={disabled}
                    />

                    <label htmlFor={htmlFor}>
                        {withLabel && (
                            <span
                                className="checkbox__labelText"
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        )}
                        <div className="checkbox__labelBox" />
                    </label>
                </div>
            </div>
        );
    }
);

export default Checkbox;
