import React, { useState } from "react";
import "./ParentalConsent.scss";
import BackgroundGradient from "components/background-gradient";
import easings from "constants/easings";
import withLocation from "hooks/withLocation";
import { motion } from "framer-motion";
import LoadingIcon from "components/loading-icon";
import Checkbox from "components/checkbox";

const ParentalConsent = React.memo(({ search, labels }) => {
    const [approved, setApproved] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [loading, setLoading] = useState(false);

    const animateArticleItem = {
        initial: {
            y: 20,
            opacity: 0,
        },
        enter: {
            y: 0,
            opacity: 1,
            transition: {
                type: "tween",
                ease: easings.one,
                duration: 0.6,
            },
        },
    };

    const containerVariants = {
        closed: { opacity: 1, y: 0 },
        open: { opacity: 0, y: 20 },
    };

    const acceptConsent = () => {
        const data = {
            data: {
                uid: search.id,
            },
        };
        return fetch(
            "https://europe-west1-superwe-api.cloudfunctions.net/parentalConsent",
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((data) => data);
    };

    const acceptTerms = () => {
        if (termsAccepted) {
            setLoading(true);
            if (search.id) {
                acceptConsent().then((result) => {
                    setLoading(false);
                    if (result.result.msg === "ok") {
                        setApproved(true);
                    }
                });
            } else {
                setLoading(false);
            }
        } else {
            alert(labels.acceptTermsMessage);
        }
    };

    return (
        <motion.div variants={animateArticleItem} className="parent-consent">
            {search.email && (
                <span className="parent-consent__email">
                    {labels.userEmailLabel}: {search.email}
                </span>
            )}
            <div className="parent-consent__container">
                <motion.div
                    animate={approved ? "open" : "closed"}
                    variants={containerVariants}
                    className="parent-consent__checkbox"
                >
                    <Checkbox
                        id="consent"
                        name="consent"
                        checked={termsAccepted}
                        onChange={(event) =>
                            setTermsAccepted(event.target.checked)
                        }
                        withLabel={true}
                        htmlFor="consent"
                        text={labels.checkboxText}
                    />
                </motion.div>
                <motion.button
                    animate={approved ? "open" : "closed"}
                    variants={containerVariants}
                    disabled={loading}
                    type="button"
                    onClick={acceptTerms}
                >
                    <BackgroundGradient />
                    <span className={loading ? "loading" : ""}>
                        {labels.submitLabel}
                    </span>
                    {loading && <LoadingIcon />}
                </motion.button>
                {approved && (
                    <motion.div
                        className="approved"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{
                            opacity: 1,
                            y: 0,
                        }}
                    >
                        <BackgroundGradient />
                        <span>{labels.approvedLabel}</span>
                    </motion.div>
                )}
            </div>
        </motion.div>
    );
});

export default withLocation(ParentalConsent);
